<script setup lang="ts">
import type { IR6ProfileEntity } from "@secret-stats/r6-api";
import { getR6Profile } from "@secret-stats/r6-api";
import { searchR6Profiles } from "@secret-stats/r6-api";
import { groupBy } from "lodash-es";

interface MonitoredProfile {
	profileId: string;
	note: string;
	createdAt: string;
}

interface ProfileWithNote extends IR6ProfileEntity {
	note: string;
}

const name = ref("");
const note = ref("");
const maxDaysAgo = ref(2);
const hideInGamePlayers = useCookie<boolean>("hideInGamePlayers", {
	default: () => true,
});
const selectedRegions = useCookie<string[]>("selectedRegions", {
	default: () => [],
});

const { data: monitoredProfiles, refresh } = await useAsyncData(async () => {
	const monitoredProfiles = await $fetch<MonitoredProfile[]>("/api/profiles/monitor", {
		method: "GET",
	});
	const profileRequests = monitoredProfiles.map(({ profileId }) => getR6Profile(profileId));
	const profiles = (await Promise.all(profileRequests)).map(({ data }) => data);
	return profiles.map(
		(profile, index) =>
			({
				...profile,
				note: monitoredProfiles[index].note,
			}) as ProfileWithNote,
	);
});

const { data: searchResults, refresh: refreshSearchResults } = await useAsyncData(
	async () => {
		// @ts-ignore
		return searchR6Profiles({
			platform: "uplay",
			displayName: name.value.trim(),
		});
	},
	{ immediate: false, lazy: true },
);

const regions = computed<string[]>(() => {
	return Array.from(
		new Set(monitoredProfiles.value?.map((profile) => profile.lastMatch?.datacenter).filter((r) => r)),
	) as string[];
});

const profilesToShow = computed(() => {
	const profiles = monitoredProfiles.value;
	if (!profiles) {
		return null;
	}

	const today = new Date();

	const profilesWithLastMatch = profiles.filter((profile) => profile.lastMatch);
	const profilesNotInGame = profilesWithLastMatch.filter((profile) =>
		hideInGamePlayers.value ? !profile.inGame : true,
	);
	const profilesWithinDaysAgo = profilesNotInGame.filter((profile) => {
		const lastMatchDate = new Date(profile.lastMatch!.endTime);
		return Math.abs(differenceInDays(today, lastMatchDate)) < maxDaysAgo.value;
	});
	const profilesGroupedByDatacenter = groupBy(profilesWithinDaysAgo, (profile) => profile.lastMatch!.datacenter);
	const profilesSortedByInGame = Object.fromEntries(
		Object.entries(profilesGroupedByDatacenter).map(([region, profiles]) => {
			return [
				region,
				profiles.toSorted((aProfile, bProfile) => {
					return Number(aProfile.inGame) - Number(bProfile.inGame);
				}),
			];
		}),
	);

	if (selectedRegions.value.length > 0) {
		Object.keys(profilesSortedByInGame).forEach((region) => {
			if (!selectedRegions.value.includes(region)) {
				delete profilesSortedByInGame[region];
			}
		});
	}

	return profilesSortedByInGame;
});

async function add() {
	if (name.value.length < 3) {
		console.error("Enter a longer name");
		return;
	}

	//@ts-ignore;
	const resultsRaw = await searchR6Profiles({
		displayName: name.value,
		platform: "uplay",
	});
	const [firstProfile] = resultsRaw.data;
	if (!firstProfile) {
		console.error("Couldn't find a profile");
		return;
	}

	try {
		const newMonitoredProfile = await $fetch<MonitoredProfile>("/api/profiles/monitor", {
			method: "POST",
			body: { profileId: firstProfile.profileId, note: note.value },
		});

		const profile = await getR6Profile(newMonitoredProfile.profileId);
		monitoredProfiles.value?.push({
			...profile.data,
			note: newMonitoredProfile.note,
		});
	} catch (e) {
		console.error(e);
	} finally {
		name.value = "";
		note.value = "";
	}
}

async function remove(profileId: string) {
	try {
		await $fetch(`/api/profiles/monitor/${profileId}`, { method: "DELETE" });
		monitoredProfiles.value = monitoredProfiles.value?.filter((profile) => profile.profileId !== profileId) ?? null;
	} catch (e) {
		console.error(`Failed to delete ${profileId}`);
	}
}

useIntervalFn(refresh, 15 * 1000);
</script>

<template>
	<main class="flex flex-col gap-4">
		<div class="flex gap-2">
			<div class="flex flex-col gap-2">
				<span class="text-contrast-500">Add by exact name</span>
				<div class="relative">
					<input
						v-model="name"
						type="text"
						class="bg-base-200 border-base-100 rounded-md border px-4 py-2 shadow-lg"
						@keydown.enter="add"
						@input="refreshSearchResults()"
					/>
					<div
						v-if="searchResults?.data && name.length > 3"
						class="bg-base-200 border-base-100 absolute left-0 top-12 flex w-full flex-col overflow-clip rounded-md border shadow-lg"
					>
						<button
							v-for="result in searchResults.data ?? []"
							@click="
								name = result.displayName;
								searchResults.data = [];
							"
							class="hover:bg-base-300 hover:text-primary-100 w-full px-4 py-2 text-left text-sm"
						>
							<span>{{ result.displayName }}</span>
						</button>
					</div>
				</div>
			</div>
			<div class="flex flex-col gap-2">
				<span class="text-contrast-500">Note (optional)</span>
				<input
					v-model="note"
					type="text"
					class="bg-base-200 border-base-100 rounded-md border px-4 py-2 shadow-lg"
					@keydown.enter="add"
				/>
			</div>
			<button
				class="h-10.5 bg-base-200 border-base-100 hover:text-primary-100 mt-8 rounded-md border px-4 py-2 shadow-lg transition-colors"
				@click="add"
			>
				Add
			</button>
		</div>
		<div class="flex justify-between">
			<div class="flex flex-col">
				<div class="flex items-center gap-2">
					<input v-model="hideInGamePlayers" type="checkbox" name="" id="" />
					<span>Hide in-game players</span>
				</div>
				<div class="flex items-center gap-2">
					<span>&lt;=</span>
					<input
						v-model="maxDaysAgo"
						type="number"
						name=""
						id=""
						class="bg-base-100 w-6 rounded-md text-center"
					/>
					<span>days ago</span>
				</div>
			</div>
			<select v-model="selectedRegions" multiple class="bg-base-300 max-h-14">
				<option v-for="region in regions" :value="region">
					{{ region }}
				</option>
			</select>
		</div>
		<div v-if="profilesToShow" class="bg-base-300 flex flex-col rounded-md shadow-lg">
			<div v-for="(profiles, region) in profilesToShow" :key="region" class="flex flex-col text-sm">
				<div class="bg-base-500 border-base-100 border-b px-4 py-2 shadow-lg">
					<span>{{ region }}</span>
				</div>
				<div class="text-contrast-300 flex flex-col px-4 pb-3 pt-1">
					<div
						v-for="profile in profiles"
						:key="profile.profileId"
						class="border-base-100 flex items-center justify-between gap-2 border-b py-1 last:border-0"
					>
						<div class="flex flex-col">
							<div class="group flex gap-2">
								<a :href="`https://dev.secretstats.net/siege/-/${profile.profileId}`" target="_blank">
									{{ profile.displayName }}
								</a>
								<button class="hidden group-hover:block" @click="() => remove(profile.profileId)">
									<Icon name="ph:trash" class="hover:text-error transition-all" />
								</button>
							</div>
							<span class="text-contrast-900 text-sm">{{ profile.note }}</span>
						</div>
						<div class="flex flex-col items-end">
							<span v-if="profile.inGame" class="text-success">In game</span>
							<span v-else class="text-error">Out of game</span>
							<div>{{ toRelativeDate(profile.lastMatch?.endTime) }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
